import React, { FC, ReactElement } from 'react';
import { Box, Text } from 'components';

interface MottoProps {
  icon?: ReactElement;
  title: string;
  description: string;
  variant?: 'dark' | 'light';
}

const Motto: FC<MottoProps> = ({
  icon,
  title,
  description,
  variant = 'dark',
}) => (
  <Box
    sx={{
      flexGrow: 1,
      flexBasis: 0,
      width: 'auto',
      py: 7,
      px: [0, 0, 2],
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon && (
        <Box
          sx={{
            display: 'inline-block',
            mr: 20,
            minWidth: 'auto',
            maxHeight: '3rem',
          }}
        >
          {icon}
        </Box>
      )}
      <Text
        variant={variant === 'dark' ? 'contentTitle' : 'contentTitleLight'}
        sx={{
          p: 0,
          display: 'inline-block',
          fontWeight: 'semibold',
        }}
      >
        {title}
      </Text>
    </Box>
    <Text
      variant={variant === 'dark' ? 'underContent' : 'underContentLight'}
      sx={{
        fontWeight: 'normal',
        pt: [18],
      }}
    >
      {description}
    </Text>
  </Box>
);

export default Motto;
