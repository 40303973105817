import React, { FC, useEffect } from 'react';
import { useIntl } from 'context/IntlContext';
import { useColorMode } from 'theme-ui';

import {
  Image,
  Flex,
  Box,
  Text,
  Heading,
} from 'components';

import Section from '../../components/Section';
import TwoColSection from '../../components/TwoColSection';
import DirectSignup from '../../components/DirectSignup';

import LineChartIcon from '../../images/icons/lineChart.svg';
import StarIcon from '../../images/icons/star.svg';
import DollarUpdateIcon from '../../images/icons/dollarUpdate.svg';
import coverImage from '../../images/img3@2x.png';
import coverImage2 from '../../images/img4@2x.png';
import Motto from '../../components/Motto';
import Seo from '../../components/SEO';

const PAGE_COLOR_MODE = 'light';

const AboutUs: FC = () => {
  const { t } = useIntl();

  const [colorMode, setColorMode] = useColorMode();
  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);

  return (
    <>
      <Seo
        title={t('global.meta.title')}
        description={''}
      />
      <TwoColSection
        left={
          <>
            <Heading
              as="h1"
              sx={{
                variant: 'text.heroTitle',
                color: 'quaternary',
                mb: 7,
              }}
            >
              {t('homepage.company.about_us.hero.title')}
            </Heading>
            <Text
              as="p"
              variant="underHero"
              sx={{
                color: 'quaternary',
                opacity: '0.8',
                mb: 10,
              }}
            >
              {t('homepage.company.about_us.hero.subtitle')}
            </Text>
          </>
        }
        right={
          <Image
            src={coverImage}
            alt="Mobile Phone"
            sx={{
              display: 'block',
              width: 'inherit',
              height: 'auto',
              margin: '0 auto',
            }}
          />
        }
      >
        <Flex sx={{ flexDirection: ['column', 'column', 'column', 'row'] }}>
          <Motto
            title={t('homepage.company.about_us.trust_section.account.title')}
            description={t(
              'homepage.company.about_us.trust_section.account.subtitle',
            )}
            icon={<LineChartIcon />}
          />
          <Motto
            title={t('homepage.company.about_us.trust_section.platform.title')}
            description={t(
              'homepage.company.about_us.trust_section.platform.subtitle',
            )}
            icon={<StarIcon />}
          />
          <Motto
            title={t(
              'homepage.company.about_us.trust_section.beyond_banking.title',
            )}
            description={t(
              'homepage.company.about_us.trust_section.beyond_banking.subtitle',
            )}
            icon={<DollarUpdateIcon />}
          />
        </Flex>
      </TwoColSection>
      <Section>
        <Heading
          as="h1"
          sx={{
            variant: 'text.sectionTitle',
            color: 'quaternary',
            mb: 7,
            textAlign: 'center',
          }}
        >
          {t('homepage.company.about_us.building_section.title')}
        </Heading>
        <Text
          color="quaternary"
          sx={{
            textAlign: 'center',
          }}
        >
          {t('homepage.company.about_us.building_section.subtitle')}
        </Text>
      </Section>
      <TwoColSection
        noDivider
        right={
          <>
            <Heading
              as="h1"
              sx={{
                variant: 'text.sectionTitle',
                color: 'quaternary',
                mb: 7,
              }}
            >
              {t('homepage.company.about_us.real_people_section.title')}
            </Heading>
            <Text
              as="p"
              variant="underHero"
              sx={{
                color: 'quaternary',
                opacity: '0.8',
                mb: 10,
              }}
            >
              {t('homepage.company.about_us.real_people_section.subtitle')}
            </Text>
          </>
        }
        left={
          <Image
            src={coverImage2}
            alt="Mobile Phone"
            sx={{
              display: 'block',
              width: 'inherit',
              height: 'auto',
              margin: '0 auto',
            }}
          />
        }
      />
    </>
  );
};

export default AboutUs;
