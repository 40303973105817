import React, { ReactElement, FC } from 'react';
import { Box, Grid } from 'components';
import Section from '../Section';

interface TwoColSectionProps {
  left: ReactElement;
  right: ReactElement;
}

const TwoColSection: FC<TwoColSectionProps> = ({ left, right, children }) => {
  return (
    <Section>
      <Grid gap={[0, 0, 14]} columns={[1, 1, '1fr 1fr']}>
        <Box>{left}</Box>
        <Box>{right}</Box>
      </Grid>
      {children}
    </Section>
  );
};

export default TwoColSection;
